import { AfterViewInit, Component, ElementRef, Renderer2, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'search-everything-container',
  templateUrl: './search-everything-container.component.html',
  styleUrl: './search-everything-container.component.scss'
})
export class SearchEverythingContainerComponent implements AfterViewInit {
    searching: boolean = false
    htmlBody: any
    
    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
    ) {
    }
    
    ngAfterViewInit(): void {
        this.htmlBody = this.el.nativeElement.ownerDocument.body
    }
    
    showSearch(): void {
        this.searching = true
        this.makeBodyUnscrollable()
    }
    
    closeSearch(): void {
        this.searching = false
        this.makeBodyScrollable()
    }
    
    makeBodyUnscrollable(): void {
        this.renderer.addClass(this.htmlBody, 'no-scroll')
    }
    
    makeBodyScrollable(): void {
        this.renderer.removeClass(this.htmlBody, 'no-scroll')
    }
}
