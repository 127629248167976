import { Component, ViewChild, OnInit, Renderer2, ElementRef, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ModalService, RouteService, SocketioService, ModalBottomService, HeaderService, TutorialService } from './_services/';
import { ModalComponent } from './shared/common/components/modal/modal.component';
import { PublicPlusButtonComponent } from './shared/common/components/public-plus-button/public-plus-button.component'
import { NavigationService } from './_services';
import { ModalBottomComponent } from './shared/common/components/modal-bottom/modal-bottom.component';
import { init } from '@fullstory/browser';
import { NotificationService } from './_services/notification.service';
import { Subscription } from 'rxjs';
import { ScrollService } from './_services/scroll.service';
import { TutorialModalComponent } from './shared/tutorials/components/tutorial-modal/tutorial-modal.component';
import { environment } from 'src/environments/environment'
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild(ModalComponent) modal: ModalComponent | null = null
    @ViewChild(PublicPlusButtonComponent) publicPlus: PublicPlusButtonComponent | null = null
    @ViewChild(ModalBottomComponent) modalBottom: ModalBottomComponent | null = null
    @ViewChild(TutorialModalComponent) tutorialModal: TutorialModalComponent | null = null

    private subscriptions: Subscription = new Subscription()

    title = 'Hub Hive'

    constructor(
        private router: Router,
        private gtmService: GoogleTagManagerService,
        private modalService: ModalService,
        private modalBottomService: ModalBottomService,
        private navigationService: NavigationService,
        private routeService: RouteService, // Don't delete. It's being used even though it looks like it's not
        private socketioService: SocketioService,
        private notificationService: NotificationService,
        private headerService: HeaderService,
        private renderer: Renderer2,
        private el: ElementRef,
        private scrollService: ScrollService, // Don't delete. It's being used even though it looks like it's not
        private tutorialService: TutorialService,
        private meta: Meta,
    ) {
        this.navigationService.handleInitialNavigation()
        this.router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
                const gtmTag = {
                    event: 'page',
                    pageName: item.url
                };

                this.gtmService.pushTag(gtmTag);
            }
        })

        this.socketioService.connect()
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    ngOnInit(): void {
        init({ orgId: 'o-1ZRA8Q-na1' })
        //this.notificationService.requestPermission()
        this.subscriptions.add(
            this.headerService.unstickHeader.subscribe(unstickHeader => {
                const element = this.el.nativeElement.querySelector('.public-header');

                this.renderer.setStyle(element, 'position', 'sticky');
                if (unstickHeader) {
                    this.renderer.setStyle(element, 'position', 'static');
                }
            })
        )

        this.setAppleStoreMetaTag()
        // this.subscriptions.add(
        //     this.routeService.getCurrentRoute().subscribe( route => {
        //         this.setAppleStoreMetaTag(route)   
        //     })    
        // )
    }

    setAppleStoreMetaTag(): void {
        this.meta.updateTag({
            name: 'apple-itunes-app',
            content: `app-id=${environment.appleAppId}, app-argument=${environment.fiber}`
        });
    }

    ngAfterViewInit(): void {
        if (this.modal) {
            this.modalService.bindModalComponent(this.modal)
        } else {
            console.error('Modal component is not available at the time of binding.')
        }

        if (this.modalBottom) {
            this.modalBottomService.bindModalComponent(this.modalBottom)
        }

        if (this.tutorialModal) {
            this.tutorialService.bindModalComponent(this.tutorialModal)
        }
    }
}
