import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { PublicHeaderComponent } from './components/public-header/public-header.component';
import { PublicFooterComponent } from './components/public-footer/public-footer.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { HeaderService } from '../_services';
import { AccountNavigationComponent } from './components/account-navigation/account-navigation.component';
import { SharedCommonModule } from '../shared/common/shared-common.module';
import { HubHiveNotificationsComponent } from './components/notifications/hub-hive-notifications.component';
import { DiscussionsModule } from '../shared/discussions/discussions.module';

@NgModule({
  declarations: [
    PublicHeaderComponent,
    PublicFooterComponent,
    MainMenuComponent,
    AccountNavigationComponent,
    HubHiveNotificationsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    SharedCommonModule,
    DiscussionsModule,
  ],
  exports: [
    PublicHeaderComponent,
    PublicFooterComponent,
    HubHiveNotificationsComponent,
  ],
  providers: [
    HeaderService
  ]
})
export class CorePublicModule { 
    constructor(@Optional() @SkipSelf() parentModule: CorePublicModule) {
        if (parentModule) {
          throw new Error(`${parentModule} has already been loaded. Import Core Public module in the PublicModule only.`);
        }
    }
}
