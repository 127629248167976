import { Component, OnInit } from '@angular/core';
import { TermsAPIService } from 'src/app/_api-services/terms-api.service';
import { HeaderService, NavigationService } from 'src/app/_services';
import { marked } from 'marked';

@Component({
  selector: 'terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
})
export class TermsOfServiceComponent implements OnInit {
  termsHtml: string = ''; 

  constructor(
    private navigationService: NavigationService,
    private headerService: HeaderService,
    private termsAPIService: TermsAPIService
  ) {
    this.headerService.setHeaderState(false, true);
  }

  async ngOnInit(): Promise<void> {
    this.termsAPIService.getTerms().subscribe(async (markdown) => {
      this.termsHtml = await marked.parse(markdown);
    });
  }

  goBack() {
    this.navigationService.goBack();
  }
}
