import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchType } from 'src/app/_enums';
import { Business, Hive, HubhiveEvent, Post, Product } from 'src/app/_models';
import { BusinessAPIService, DiscussionAPIService, EventAPIService, HiveAPIService } from 'src/app/_api-services';
import { ProductAPIService } from 'src/app/_api-services/product-api.service';
import { HeaderService } from 'src/app/_services';

@Component({
  selector: 'main-landing',
  templateUrl: './main-landing.component.html',
  styleUrl: './main-landing.component.scss'
})
export class MainLandingComponent  {

    constructor(
        private headerService: HeaderService,
    ) {
        this.headerService.setHeaderState(false, true)
    }
}
