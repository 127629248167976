<div class="alerts" #alerts (click)="openMenu($event)">
    <img *ngIf="showLightIcons && !hasUnreadNotifications" src="/assets/icon-bell-light.svg" alt="alert icon">
    <img *ngIf="showLightIcons && hasUnreadNotifications" src="/assets/icon-bell-light-badge.svg" alt="alert icon">
    <img *ngIf="!showLightIcons && !hasUnreadNotifications" src="/assets/icon-bell.svg" alt="alert icon">
    <img *ngIf="!showLightIcons && hasUnreadNotifications" src="/assets/icon-bell-dark-badge.svg" alt="alert icon">
</div>

<nav [ngClass]="{'open': navOpen }">
    <div class="row">
        <h4>Notifications</h4>
        <img class="close" src="/assets/icon-close.svg" alt="close menu" (click)="closeMenu()">
    </div>
    <div class="row">
        <p (click)="markAllAsRead()" class="read">Mark all as read</p>
    </div>

    <div>
        <notification-card *ngFor="let hubHiveNotification of hubHiveNotifications" 
            [hubHiveNotification]="hubHiveNotification" 
            (click)="closeMenu()" 
            (readStateChanged)="checkForUnreadNotifications()">
        </notification-card>
    </div>
</nav>


<notification-card *ngIf="liveNotification" [ngClass]="{'visible': showLiveNotification}"
    [hubHiveNotification]="liveNotification" 
    (click)="closeMenu()" class="live-notification" (readStateChanged)="checkForUnreadNotifications()"></notification-card>