import { Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BusinessAPIService, EventAPIService, HiveAPIService } from 'src/app/_api-services';
import { Business, Hive, HubhiveEvent, User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { AccountNavigationService } from 'src/app/_services/account-navigation.service';

@Component({
  selector: 'account-navigation',
  templateUrl: './account-navigation.component.html',
  styleUrl: './account-navigation.component.scss'
})
export class AccountNavigationComponent implements OnChanges, OnDestroy {
    @Input() showLightIcons: boolean = false
    
    private subscriptions: Subscription = new Subscription()
    
    user!: User
    navOpen: boolean = false
    userBusinesses: Business[] = []
    userHives: Hive[] = []
    claimedBusinesses: Business[] = []
    myEvents: HubhiveEvent[] = []
    
    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private eRef: ElementRef, 
        private businessAPIService: BusinessAPIService,
        private hiveAPIService: HiveAPIService,
        private eventAPIService: EventAPIService,
        private accountNavigationService: AccountNavigationService
    ) {
        
        this.subscriptions.add(
            this.authService.currentUserSubject.subscribe( authUser => {
                this.user = authUser.user
            })
        )
        
        this.subscriptions.add(
            this.businessAPIService.getMyBusinesses().subscribe({
                next: businesses => {
                    let userBusinesses = businesses ? businesses : []
                    this.accountNavigationService.myBusinesses.next(userBusinesses)
                }
            })
        )
        
        this.subscriptions.add(
            this.hiveAPIService.getMyHives().subscribe({
                next: hives => {
                    let userHives = hives ? hives : []
                    this.accountNavigationService.myHives.next(userHives)
                }
            })
        )
        
        this.subscriptions.add(
            this.businessAPIService.getMyBusinessClaims().subscribe({
                next: businesses => {
                    let claimedBusinesses = businesses ? businesses : []
                    this.accountNavigationService.myBusinessClaims.next(claimedBusinesses)
                }
            })
        )
        
        this.subscriptions.add(
            this.eventAPIService.getMyEvents().subscribe({
                next: events => {
                    let myEvents = events ? events : []
                    this.accountNavigationService.myEvents.next(myEvents)
                }
            })
        )
        
        this.subscriptions.add(
            this.accountNavigationService.myBusinesses.subscribe( businesses => {
                this.userBusinesses = businesses ? businesses : []
            })
        )
        
        this.subscriptions.add(
            this.accountNavigationService.myHives.subscribe( hives => {
                this.userHives = hives ? hives : []
            })
        )
        
        this.subscriptions.add(
            this.accountNavigationService.myBusinessClaims.subscribe( businesses => {
                this.claimedBusinesses = businesses ? businesses : []
            })
        )
        
        this.subscriptions.add(
            this.accountNavigationService.myEvents.subscribe( events => {
                this.myEvents = events ? events : []
            })
        )
        
        this.subscriptions.add(
            this.businessAPIService.listenForBusinessApproval(this.user.id).subscribe( business => {
                this.accountNavigationService.removeMyClaims(business.id)
                this.accountNavigationService.addMyBusiness(business)
            })
        )
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        this.showLightIcons = changes['showLightIcons'].currentValue
    }
    
    @HostListener('document:click', ['$event'])
    closeMenuOnClick(event: Event): void {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.navOpen = false;
        }
    }
    
    openMenu(e: Event): void {
        e.stopPropagation()
        this.navOpen = true
    }
    
    closeMenu(): void {
        this.navOpen = false
    }
    
    logout(): void {
        this.authService.logout()
        this.router.navigate([''])
    }
}
