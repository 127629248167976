<div class="flex align-center">
    <span (click)="goBack()" class="back"><img src="/assets/icon-back-black.svg"></span>
</div>
<div class="flex flex-column flex-center">
    <div class="container">
        
        <h1>HubHive Privacy Policy</h1>
        <p>Effective Date: November 4, 2024</p>
        
        <ol>
            <li>
                <div class="section">
                    <p class="section-title">Introduction</p>
                    <p>Welcome to HubHive! Your privacy is important to us. This Privacy Policy explains what information we collect, how we use and share it, and your rights regarding your data.</p>
                </div>
            </li>
            <li>
                <div class="section">
                    <p class="section-title">Information We Collect</p>
                    <ul>
                        <li><strong>Personal Information</strong>: We collect limited personal information, such as your name, email address, and profile information.</li>
                        <li><strong>Usage Data</strong>: We gather data on how you interact with our services, including the communities you join, the content you view, and your activity on the platform.</li>
                        <li><strong>Location Data</strong>: We do not track precise location data beyond city you are in. </li>
                        <li><strong>Mobile Data</strong>: If you use our mobile platform, we may collect data related to your device, such as device identifiers, mobile network information, and limited data on app interactions for SMS notifications. This data will solely support communication and app functionality.</li>
                    </ul>     
                </div>
            </li>
            <li>
                <div class="section">
                    <p class="section-title">How We Use Your Information</p>
                </div>
                <div class="section">
                    <p class="section-title">We use the information we collect for the following purposes:</p>
                    <ul>
                        <li><strong>Enhancing User Experience</strong>: Personalize your experience by showing relevant content and communities.</li>
                        <li><strong>Targeted Advertising</strong>: Display personalized ads based on your interests and activity.</li>
                        <li><strong>Community Suggestions</strong>: Recommend communities and content that may interest you.</li>
                        <li><strong>SMS Notifications</strong>: Use mobile data to send SMS notifications related to account activities, updates, or relevant community alerts.</li>
                    </ul>
                </div>
            </li>
            <li>
                <div class="section">
                    <p class="section-title">How We Share Your Information</p>
                    <p>We primarily use the data we collect to provide and improve our services. We do not sell your personal or mobile data to third parties or work with data brokers.</p>
                </div>
            </li>
            <li>
                <div class="section">
                    <p class="section-title">Your Rights and Choices</p>
                </div>
                <div class="section">
                    <p class="section-title">You have the following rights regarding your data:</p>
                    <ul>
                        <li><strong>Access Your Data</strong>: Request a copy of your data.</li>
                        <li><strong>Account Deletion</strong>: Request deletion of your account and associated data.</li>
                        <li><strong>Policy Information</strong>: Request information about our data practices.</li>
                    </ul>
                </div>
            </li>
            <li>
                <div class="section">
                    <p class="section-title">Data Security</p>
                </div>
                <div class="section">
                    <p class="section-title">We take measures to protect your information from loss, theft, misuse, and unauthorized access. For example:</p>
                    <ul>
                        <li>Encryption: Use HTTPS for data transmission.</li>
                        <li>Access Controls: Limit employee access to non-public personal information.</li>
                        <li>Two-Factor Authentication: Encourage users to enable this for account security.</li>
                    </ul>
                </div>
            </li>
            <li>
                <div class="section">
                    <p class="section-title">Data Retention</p>
                    <p>We retain information as long as necessary for the purposes for which it was collected. We may keep certain data for legitimate business purposes or as required by law, such as preventing banned users from creating new accounts.</p>
                </div>
            </li>
            <li>
                <div class="section">
                    <p class="section-title">Contact Us</p>
                    <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@hubhive.com">info&#64;hubhive.com</a>.</p>
                </div>
            </li>
        </ol>
    </div>    
</div>