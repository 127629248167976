import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Hive, Slide, UserHub } from 'src/app/_models';
import { UserAPIService, HiveAPIService } from 'src/app/_api-services';
import { HeaderService, ModalService, TutorialService } from 'src/app/_services';

@Component({
  selector: 'user-hub',
  templateUrl: './user-hub.component.html',
  styleUrl: './user-hub.component.scss'
})
export class UserHubComponent implements OnDestroy {
    private subscriptions: Subscription = new Subscription()
    
    @ViewChild('hiveTutorial') hiveTutorial!: TemplateRef<any>
    @ViewChild('businessTutorial') businessTutorial!: TemplateRef<any>
    @ViewChild('productTutorial') productTutorial!: TemplateRef<any>
    @ViewChild('hiveModal') hiveModal!: TemplateRef<any>
    @ViewChild('businessModal') businessModal!: TemplateRef<any>
    @ViewChild('eventModal') eventModal!: TemplateRef<any>
    
    userHub!: UserHub
    suggestedHives!: Hive[]
    userHubLoading: boolean = true
    hivesLoading: boolean = true
    slides: Slide[] = []
    
    constructor(
        private userAPIService: UserAPIService, 
        private router: Router,
        private hiveAPIService: HiveAPIService,
        private tutorialService: TutorialService,
        private modalService: ModalService,
        private headerService: HeaderService,
    ) {
        this.headerService.setHeaderState(true, false)
        
        this.subscriptions.add(
            this.userAPIService.getUserHub().subscribe( {
                next: (userHub) => {
                    console.log(userHub)
                    this.userHub = userHub
                    this.userHubLoading = false
                    this.setTutorialSlides()
                },
                error: (err) => {
                    this.hivesLoading = false
                    if(err.status == 401) {
                        this.router.navigate(['/login'], { queryParams: { redirect: this.router.url } } )
                    }
                }
            })
        )  
        this.subscriptions.add(
            this.hiveAPIService.getHivesUserNotIn(5).subscribe({
                next: hives => {
                    this.suggestedHives = hives
                    this.hivesLoading = false
                },
                error: err => {
                    this.hivesLoading = false
                }
            })
        )
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    setTutorialSlides(): void {
        if(!this.userHub || !this.userHub.hives || !this.userHub.hives.length) {
            this.slides.push({ 
                src: '/assets/create-new-hive.png',
                srcset: '/assets/create-new-hive.png 1x, /assets/create-new-hive@2x.png 2x', 
                title: 'Create a new Hive', 
                caption: 'Build a thriving community full of people, businesses, goods, and services.',
                callback: () => {
                    this.doHiveTutorial()
                },
            })
        }
        
        if(!this.userHub || !this.userHub.businesses || !this.userHub.businesses.length) {
            this.slides.push({ 
                src: '/assets/add-your-business.png',
                srcset: '/assets/add-your-business.png 1x, /assets/add-your-business@2x.png 2x', 
                title: 'Add your business', 
                caption: 'Create a business profile to share it with other hives to help your goods, services, and events become discovered.',
                callback: () => {
                    this.doBusinessTutorial()
                }, 
            })
        }
        
        if(!this.userHub || !this.userHub.my_events || !this.userHub.my_events.length) {
            this.slides.push({ 
                src: '/assets/create-an-event.png',
                srcset: '/assets/create-an-event.png 1x, /assets/create-an-event@2x.png 2x', 
                title: 'Create an event', 
                caption: 'Whether it\'s a backyard BBQ, local farmers market, or business conference; create an event and share it inside of hives you\'re a part of.' ,
                callback: () => {
                    this.doEventTutorial()
                },
            })
        }
    }
    
    doHiveTutorial(): void {
        this.tutorialService.open(this.hiveTutorial)
    }
    
    doBusinessTutorial(): void {
        this.modalService.open("Create New Business", this.businessModal)
    }
    
    doEventTutorial(): void {
        this.modalService.open("Create Event", this.eventModal)
    }
    
    openModal(modal: TemplateRef<any>): void {
        this.modalService.open("Create New Hive", modal)
    }
}
