import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Config } from '../_models'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class TermsAPIService {
    constructor(private http: HttpClient) { }

    getTerms(): Observable<string> {
        return this.http.get(`${Config.apiUrl}/terms`, { responseType: 'text' });
    }
}