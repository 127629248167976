import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService, HeaderService, NavigationService } from 'src/app/_services';

@Component({
  selector: 'home-container',
  templateUrl: './home-container.component.html',
  styleUrl: './home-container.component.scss'
})
export class HomeContainerComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription()
    
    isLoggedIn: boolean = false
    
    constructor(
        private authService: AuthenticationService,
        private headerService: HeaderService,
        private navigationService: NavigationService,
    ) {
        this.authService.currentUserSubject.subscribe( user => {
            this.isLoggedIn = this.authService.isLoggedIn()        
        })
        
        this.isLoggedIn = this.authService.isLoggedIn()
    }

    ngOnInit(): void {
        this.navigationService.clearHistory()
        this.setHeaderState()
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    private setHeaderState(): void {
        this.headerService.setHeaderState(true, false)
    }
    
    restoreState(): void {
        this.setHeaderState()
    }
}
