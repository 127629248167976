<div class="nav-avatar" (click)="openMenu($event)">
    <missing-avatar *ngIf="!user.avatar" [user]="user" class="small"></missing-avatar>
    <img *ngIf="user.avatar" src="{{ user.avatar }}" alt="user avatar">
</div>

<nav [ngClass]="{'open': navOpen }">
    
    <div class="nav-section">
        <div class="row">
            <h4>Account Preferences</h4>
            <img class="close" src="/assets/icon-close.svg" alt="close menu" (click)="closeMenu()">
        </div>
        
        <div class="row" (click)="closeMenu()">
            <user-card [tileOnly]="true" [user]="user"></user-card>
        </div>
    </div>
    
    
    
    <div *ngIf="userHives.length" class="nav-section">
        <h4>Your Hives</h4>
        
        <div class="entities">
            <hive-card *ngFor="let h of userHives" [hive]="h" [type]="'tile'" (click)="closeMenu()"></hive-card>
        </div>
    </div>
    
    <div *ngIf="userBusinesses.length" class="nav-section">
        <h4>Your Businesses</h4>
        
        <div class="entities">
            <node-tile *ngFor="let b of userBusinesses"  (click)="closeMenu()"
                [link]="'/business/' + b.handle + '/details'"
                [defaultImage]="'/assets/business/default-logo.svg'"
                [image]="b.logo"
                [primaryTxt]="b.name">
            </node-tile>
        </div>
    </div>
    
    <div *ngIf="claimedBusinesses.length" class="nav-section">
        <h4>Claimed Businesses (Pending)</h4>
        
        <div class="entities">
            <node-tile *ngFor="let b of claimedBusinesses"  (click)="closeMenu()"
                [link]="'/business/' + b.handle + '/details'"
                [defaultImage]="'/assets/business/default-logo.svg'"
                [image]="b.logo"
                [primaryTxt]="b.name">
            </node-tile>
        </div>
    </div>
    
    <div *ngIf="myEvents.length" class="nav-section">
        <h4>Your Events</h4>
        
        <div class="entities">
            <node-tile *ngFor="let e of myEvents"  (click)="closeMenu()"
                [link]="'/event/' + e.name + '/details'"
                [queryParams]="{ id: e.id }"
                [defaultImage]="'/assets/default_event_image.png'"
                [image]="e.image"
                [primaryTxt]="e.name"
                [secondaryTxt]="e.start_date | dateTime">
            </node-tile>
        </div>
    </div>
        
    <button class="logout btn btn-primary" (click)="logout()">Log Out</button>
</nav>