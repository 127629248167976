import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationAPIService } from 'src/app/_api-services';
import { User } from 'src/app/_models';
import { GeneralNotification } from 'src/app/_models/general-notification.model';
import { AuthenticationService } from 'src/app/_services';
import { AudioService } from 'src/app/_services/audio.service';

@Component({
  selector: 'hub-hive-notifications',
  templateUrl: './hub-hive-notifications.component.html',
  styleUrl: './hub-hive-notifications.component.scss'
})
export class HubHiveNotificationsComponent implements OnChanges, OnDestroy {
    @ViewChild('liveNotificationEl') liveNotificationEl!: ElementRef
    @ViewChild('alerts') alerts!: ElementRef
    @Input() showLightIcons: boolean = false
    
    private subscriptions: Subscription = new Subscription()
    
    user: User
    navOpen: boolean = false
    hubHiveNotifications: GeneralNotification[] = []
    liveNotification: GeneralNotification | null = null
    showLiveNotification: boolean = false
    hasUnreadNotifications: boolean = false
    
    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private notificationAPIService: NotificationAPIService,
        private eRef: ElementRef,
        private audioService: AudioService,
        private cdr: ChangeDetectorRef,
    ) {
        this.user = this.authService.currentUserValue.user
        
        this.subscriptions.add(
            this.notificationAPIService.list(0).subscribe( hubHiveNotifications => {
                this.hubHiveNotifications = hubHiveNotifications ? hubHiveNotifications : []
                
                this.checkForUnreadNotifications()
            })
        )
        
        this.subscriptions.add(
            this.notificationAPIService.listenForNotification(this.authService.currentUserValue.user.id).subscribe( hubHiveNotification => {
                this.ringBell()
                
                this.hubHiveNotifications.unshift(hubHiveNotification)
                this.hasUnreadNotifications = true
                this.audioService.playNotificationAlert()
                
                this.liveNotification = hubHiveNotification
                this.showLiveNotification = true
                this.setRemoveLiveNotificationTimeout()
                this.cdr.detectChanges()
            })
        )
        
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        this.showLightIcons = changes['showLightIcons'].currentValue
    }
    
    checkForUnreadNotifications(): void {
        for(let n of this.hubHiveNotifications) {
            if(!n.read) {
                this.hasUnreadNotifications = true
                return 
            }
        }
        
        this.hasUnreadNotifications = false
    }
    
    @HostListener('document:click', ['$event'])
    @HostListener('document:touchstart', ['$event'])
    closeMenuOnClick(event: Event): void {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.navOpen = false;
        }
    }
    
    openMenu(e: Event): void {
        this.navOpen = true
    }
    
    closeMenu(): void {
        this.navOpen = false
    }
    
    setRemoveLiveNotificationTimeout(time: number = 5000): void {
        setTimeout(() => {
            this.showLiveNotification = false
        }, time)
    }
    
    ringBell(): void {
        let img: HTMLElement = this.alerts.nativeElement.querySelector('img')
        img.classList.add('ringing')
        
        setTimeout(() => {
            img.classList.remove('ringing')
        }, 2000)   
    }
    
    markAllAsRead(): void {
        if(!this.hasUnreadNotifications) {
            return
        }
        
        this.subscriptions.add(
            this.notificationAPIService.markAllAsRead().subscribe({
                next: notifications => {
                    this.setPropertyAsRead()
                },
                error: err => {
                    
                }
            })
        )
    }
    
    setPropertyAsRead(): void {
        for(let i = 0; i < this.hubHiveNotifications.length; i++) {
            this.hubHiveNotifications[i].read = true
        }
        
        this.hasUnreadNotifications = false
    }
    
    logout(): void {
        this.authService.logout()
        this.router.navigate([''])
    }
}
