import { SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

export const socketIoConfig: SocketIoConfig = {
    url: environment.websocketUrl, // URL of the Socket.io server
    options: {
        transports: ['websocket', 'polling'], // Use both transports for fallback
        reconnection: true,                   // Enable reconnection
        reconnectionAttempts: Infinity,       // Retry forever
        reconnectionDelay: 1000,              // Wait 1 second before retrying
        reconnectionDelayMax: 5000,
    }
};
