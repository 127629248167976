import { Injectable } from '@angular/core'
import { NavigationError, Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class ChunkErrorHandlerService {

    constructor(private router: Router) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationError) {
                if (event.error && this.isDynamicallyImportedChunkError(event.error)) {
                    // Handle the missing chunk by reloading the module
                    this.handleMissingChunk(event.url)
                }
            }
        })
    }
    
    isDynamicallyImportedChunkError(error: any): boolean {
        return error.message && error.message.includes('Failed to fetch dynamically imported module')
    }
    
    handleMissingChunk(failedUrl: string): void {
        console.log(`Failed to fetch chunk for URL: ${failedUrl}. Retrying...`);
    
        // Force reload the module by navigating to a dummy route, then back to the original URL
        this.router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() => {
            this.router.navigate([failedUrl]);
        })
      }
}
