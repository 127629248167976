<footer>
    <div>
        <nav>
            <ul class="flex">
                <!-- <li><a href="">Add Your Business</a></li>
                <li><a href="">Add Your Childcare</a></li>
                <li><a href="">Add Your Skills</a></li>
                <li><a href="">About Us</a></li>
                <li><a href="">Feedback Forum</a></li>
                <li><a href="">FAQ</a></li>
                <li><a href="">Contact Us</a></li> -->
            </ul>
        </nav>
    </div>
</footer>