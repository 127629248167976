import { Component, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService, HeaderService, NavigationService } from 'src/app/_services';

@Component({
    selector: 'public-header',
    templateUrl: './public-header.component.html',
    styleUrls: ['./public-header.component.scss']
})
export class PublicHeaderComponent implements OnDestroy {
    private subscriptions: Subscription = new Subscription()

    showLightIcons: boolean = true
    menuIsOpen: boolean = false
    hideHeader: boolean = false
    userIsLoggedIn: boolean = false
    modalActive: boolean = false

    constructor(
        private eRef: ElementRef,
        private headerService: HeaderService,
        private authService: AuthenticationService,
        private router: Router,
        private navService: NavigationService,
    ) {
        this.userIsLoggedIn = this.authService.isLoggedIn()

        this.subscriptions.add(
            this.headerService.showLightIcons.subscribe(showLight => {
                this.showLightIcons = showLight

                const header = document.documentElement
                if (showLight) {
                    header.style.setProperty('--background-color', 'transparent')
                } else {
                    header.style.setProperty('--background-color', 'white')
                }
            })
        )

        this.subscriptions.add(
            this.headerService.hideHeader.subscribe(hideHeader => {
                this.hideHeader = hideHeader
            })
        )

        this.subscriptions.add(
            this.authService.currentUser.subscribe(userSubject => {
                this.userIsLoggedIn = this.authService.isLoggedIn()
            })
        )
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }


    toggleMenu(): void {
        this.menuIsOpen = !this.menuIsOpen
    }

    @HostListener('document:click', ['$event'])
    closeMenu(event: Event): void {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.menuIsOpen = false;
        }
    }

    logout(): void {
        this.authService.logout()
        this.router.navigateByUrl('/register', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/'])
        });
    }

    navigateToAppStore() {
        this.navService.navigateToAppStore()
    }

    showModal(): void {
        this.modalActive = true
    }

    close(): void {
        this.modalActive = false
    }
}
