<div class="hamburger" [class.open]="menuIsOpen" [class.on-root]="userOnRootComponent" (click)="toggleMenu()">
    <span></span>
    <span></span>
    <span></span>
</div>

<nav class="main-menu" [class.open]="menuIsOpen">
    <span (click)="toggleMenu()" class="close">
        <img src="/assets/icon-close.svg">
    </span>
    
    <ul>
        <li (click)="toggleMenu()"><a routerLink="/"><img src="/assets/icon-home.svg" alt="home icon"> Home</a></li>
        <li (click)="toggleMenu()"><a routerLink="/hives"><img src="/assets/icon-hives.svg" alt="hives icon"> Hives</a></li>
        <li (click)="toggleMenu()"><a routerLink="/people"><img src="/assets/icon-people.svg" alt="people icon"> People</a></li>
        <li (click)="toggleMenu()"><a routerLink="/business"><img src="/assets/icon-business.svg" alt="business icon"> Businesses</a></li>
        <li (click)="toggleMenu()"><a routerLink="/products"><img src="/assets/icon-products.svg" alt="products icon"> Products</a></li>
        <!-- <li (click)="toggleMenu()"><a routerLink="/services"><img src="/assets/icon-services.svg" alt="services icon"> Services</a></li> -->
        <li (click)="toggleMenu()"><a routerLink="/event"><img src="/assets/icon-calendar.svg" alt="events icon"> Events</a></li>
        <!-- <li (click)="toggleMenu()"><a routerLink="/news"><img src="/assets/icon-news.svg" alt="news icon"> News</a></li> -->
    </ul>
</nav> 