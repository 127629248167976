import { Component, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HeaderService } from 'src/app/_services';

@Component({
  selector: 'main-menu',
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss'
})
export class MainMenuComponent implements OnDestroy {
    private subscriptions: Subscription = new Subscription()
    menuIsOpen: boolean = false
    userOnRootComponent: boolean = true
    
    
    constructor(private eRef: ElementRef, private headerService: HeaderService) {
        this.subscriptions.add(
            this.headerService.showLightIcons.subscribe( onRoot => {
                this.userOnRootComponent = onRoot
            })
        )
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    toggleMenu(): void {
        this.menuIsOpen = !this.menuIsOpen
    }
    
    @HostListener('document:click', ['$event'])
    closeMenu(event: Event): void {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.menuIsOpen = false;
        }
    }
}
