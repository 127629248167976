<div class="hub-container">
    <div class="head-container">
        <div>
            <div class="flex space-between title">
                <h1>Home</h1>
            </div>
        </div>
    </div>
</div>

<div class="page-content">
    <div class="container">
        <search-everything-container></search-everything-container>
    </div>
    
    <nav-home></nav-home>

    <div class="container">
        <section *ngIf="slides.length">
            <tutorial-slider [slides]="slides"></tutorial-slider> 
        </section>
        
        <hr class="section-border" *ngIf="slides.length">
        
        <section>
            <div *ngIf="suggestedHives && suggestedHives.length">
                <div class="title-group">
                    <h2 class="section-title">Hives are where your heart is</h2>
                    <p class="section-subtitle">Jump in and explore these trending communities</p>
                </div>
                <div class="discover-hives">
                    <hive-card *ngFor="let hive of suggestedHives" [hive]="hive" class="flex flex-column"></hive-card>
                </div>
                
                <a routerLink="/hives" class="view-all">View all hives</a>
            </div>
            <loading-spinner *ngIf="hivesLoading"></loading-spinner>
        </section>
        
        <hr class="section-border">
        
        <section>
            <div *ngIf="userHub && userHub.products">
                <div>
                    <h2 class="section-title">Let's go shopping!</h2>
                    <p class="section-subtitle">Explore products that fuel your communities</p>
                </div>
                <div class="products">
                    <product-grid-item *ngFor="let product of userHub.products" [product]="product" class="small"></product-grid-item>
                </div>
                <a routerLink="/products" class="view-all">View more products</a>    
            </div>
            <loading-spinner *ngIf="userHubLoading"></loading-spinner>
        </section>
        
        <hr class="section-border">
        
        <section>
            <div *ngIf="!userHubLoading && userHub.events">
                <div class="title-group">
                    <h2 class="section-title">Do something!</h2>
                    <p class="section-subtitle">Here are some events that might motivate you to put your socks on</p>
                </div>
                <div class="hHEvent">
                    <event-card *ngFor="let hHEvent of userHub.events" [event]="hHEvent" [outline]="true"></event-card>
                </div>    
            </div>
            
            <loading-spinner *ngIf="userHubLoading"></loading-spinner>
        </section>
    </div>
</div>

<ng-template #hiveTutorial let-data="data">
    <hive-tutorial (tutorialDone)="openModal(hiveModal)"></hive-tutorial>
</ng-template>

<ng-template #businessTutorial let-data="data">
    <business-tutorial></business-tutorial>
</ng-template>

<ng-template #hiveModal let-data="data">
    <create-hive-container></create-hive-container>
</ng-template>

<ng-template #businessModal let-data="data">
    <business-add></business-add>
</ng-template>

<ng-template #eventModal let-data="data">
    <event-create-container></event-create-container>
</ng-template>
