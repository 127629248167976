import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core'
import { NavigationEnd, NavigationStart, Router } from '@angular/router'
import { ScrollPositionService } from './scroll-position.service'
import { filter, Subscription, takeUntil } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ScrollService implements OnDestroy {
    private subscriptions: Subscription = new Subscription()
    private renderer: Renderer2
    private currentURL: string | null = null
    
    constructor(
        private rendererFactory: RendererFactory2,
        private router: Router,
        private scrollPositionService: ScrollPositionService,
    ) { 
        this.renderer = this.rendererFactory.createRenderer(null, null)
        this.initScrollPositionManagement()
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    private initScrollPositionManagement(): void {
        this.subscriptions.add(
            this.router.events.pipe(
                filter((event): event is NavigationStart => event instanceof NavigationStart),
            ).subscribe((event: NavigationStart) => {
                if (this.currentURL !== null) {
                    this.scrollPositionService.saveScrollPosition(this.currentURL)
                }
            })
        )
        
        
        this.subscriptions.add(
            this.router.events.pipe(
                filter((event): event is NavigationEnd => event instanceof NavigationEnd)
            ).subscribe((event: NavigationEnd) => {
                this.currentURL = event.urlAfterRedirects
                
                this.waitForDocumentToLoad(() => {
                    if(!this.currentURL) {
                        return
                    }
                    
                    
                    if (this.scrollPositionService.hasScrollPosition(this.currentURL)) {
                        const scrollPosition = this.scrollPositionService.getScrollPosition(this.currentURL)
                        this.scrollTo(scrollPosition)
                    }
                })
            })
        )
    }
      
    private waitForDocumentToLoad(callback: () => void): void {
        if (document.readyState === 'complete') {
            callback()
        } else {
            this.renderer.listen('window', 'load', () => {
                callback()
            })
        }
    }
      
    scrollTo(y: number, x: number = 0): void {
        setTimeout(() => {
            window.scrollTo(x, y)    
        }, 100)
        
    }
}
