<div class="page-content">
    <div class="container">
        <div class="grid flex-column content">
            <div class="flex flex-center logo">
                <img src="/assets/logo-light.svg" alt="logo">
                <p class="title">hubhive</p>
            </div>
            <div class="video">
                <iframe src="https://www.youtube.com/embed/h0n2P9gWVck?si=k-hamlpXOMZ33WsH" title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div>
                <h1>Your Community. Your Marketplace</h1>
                <p>Join communities that support each other with a marketplace of local businesses, services, and
                    products. Join our waitlist to be among the first to experience a smarter more connected way to shop
                    locally and support the people who matter most.</p>
            </div>
            <a (click)="navigateToAppstore()" class="btn btn-rounded">Download the app</a>
        </div>
    </div>
</div>