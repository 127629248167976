import { Component } from '@angular/core';
import { HeaderService, NavigationService } from 'src/app/_services';

@Component({
  selector: 'privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {
    constructor(
        private navigationService: NavigationService,
        private headerService: HeaderService,
    ) {
        this.headerService.setHeaderState(false, true)
    }
    
    goBack() {
        this.navigationService.goBack()
    }
}
