<div class="feedback-ribbon" (click)="showModal()" *ngIf="!hideHeader">
    <p>HubHive Alpha - Submit Feedback</p>
</div>
<header *ngIf="!hideHeader">
    <div class="left">
        <a routerLink="/" *ngIf="!userIsLoggedIn">
            <img srcset="/assets/dark-logo-with-words.png 1x, /assets/dark-logo-with-words@2x.png 2x" alt="hub hive connect logo">
        </a>
        
        <main-menu *ngIf="userIsLoggedIn"></main-menu>
    </div>
    
    <div *ngIf="userIsLoggedIn" class="logo center">
        <a *ngIf="showLightIcons" routerLink="/" class="logo"><img src="/assets/logo-light.svg" alt="logo"></a>
        <a *ngIf="!showLightIcons" routerLink="/" class="logo"><img src="/assets/logo.svg" alt="logo"></a>    
    </div>
    
    
    <div class="user-action right">
        <hub-hive-notifications *ngIf="userIsLoggedIn" [showLightIcons]="showLightIcons"></hub-hive-notifications>
        <account-navigation *ngIf="userIsLoggedIn"></account-navigation>
        <a *ngIf="!userIsLoggedIn" routerLink="/login" queryParamsHandling="preserve">Log in</a>
    </div>
</header>

<div class="modal-background active" *ngIf="modalActive" (click)="close()">
    <div class="modal-content">
        <div class="modal-header">
            <span class="close" (click)="close()">
                <img src="/assets/icon-close.svg">
            </span>
        </div>
        <div>
            <iframe class="airtable-embed" src="https://airtable.com/embed/app2E7WULaPoiyBr7/pagRupc007KUSWHpH/form" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;"></iframe>
        </div>
    </div>
</div>